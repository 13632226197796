<template>
    <div style="margin-top: 8px;">
        <div v-if="showPackageLoader" style="display: flex; flex: 1;  align-items:center; justify-content: center; height: 60vh;">
                    <img src="https://s3iconimages.mymedicine.com.mm/packageSearchGif.gif" alt="" style="width: 250px; height: auto;">
                </div>
        <div v-else>
            <div v-if="getPackageList.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey; height: 60vh;">
                        <img src="https://s3iconimages.mymedicine.com.mm/noContentFound.svg" alt="" style="width: 80px; height: 80px;">
                        {{$t("Customer.PackageAppointments.no_package_found")}}
                    </div>  
            <div  v-else>
                <div v-for="(item, index) in getPackageList" :key="index" style="margin-top: 24px;">
                    <ViewParticularOPDPackage  :item="item.packageInfo" :showHospitalInfo="true"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import HospitalSkeletonLoader from '../../../OPDBookingPages/componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import ViewParticularOPDPackage from '../OPDPackageViewHospitalPage/viewParticularOPDPackage.vue';


export default {
  components: { HospitalSkeletonLoader, ViewParticularOPDPackage },
    name: 'PackageFlowComp',
    
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            item: {
			"doctorInfo": {
                    "id": "ABC123445",
                    "academicTitle": [
                        "M.Med.Sc (Pediatrics)",
                        "M.B.B.S (Ygn)"
                    ],
                    "doctorLogo": "https://media.istockphoto.com/id/1365830421/vector/hands-holding-house-symbol-with-heart-shape-thick-line-icon-with-pointed-corners-and-edges.jpg?s=612x612&w=0&k=20&c=OcBjtznQ1DKxk07kYzVxH-UC9-QC6HtKlRU5cNGcmfM=",
                    "doctorNameEN": "Dr. Kuldeep Watch King",
                    "doctorNameMM": "Dr. Kuldeep Watch King",
                    "category": "Internal Medicine",
                    "location": "Yangoon",
                    "doctorSpecialty": [
                        "12341",
                        "12342"
                    ],
                    "hospitalRegNo": "ABUHK4451"
			    }
            },
            dependent_members: []

        }    
    },
    computed: {
        showPackageLoader () {
            return this.$store.state.opdPackageState.fetchingPackageData;        
        },
        getPackageList() {
            return this.$store.state.opdPackageState.showPackageListPackageFlow || [];        
        }
    },
    async mounted() {
        this.getDependentData();
        this.$store.dispatch('opdPackageState/loadPackageListPackageFlow');
    },
    methods: {
        goToHospitalPage() {
            // this.$router.push({
            // path: `/viewHospital/${this.hospitalData.hospitalRegNumber}`,
            // });
        },
        async getDependentData() {
            this.currentCustomer = this.$cookies.get("customerToken");
                let customerData = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
            };
            try {
                let dependentResponse = await axios_auth_instance_hba_customer.get("/user/dependents");
                var dependent_list = [];
                var dependent_members = dependentResponse.data.dependentList;
                this.dependent_members = dependent_members;
                this.dependent_members.push({
                    _id: 'Myself',
                    dependent_name: 'Myself',
                    NRC: this.NRC,
                });
            } catch (error) {
                console.log(error);
            } 
        },
    },
};

</script>
<style  scoped>
.mainContainer {
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
}
.headerHeading {
    color: white;
    font-weight: 600;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.hospitalLogo {
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: center;
    gap: 16px;
}
</style>